import { CloseIcon } from '@dsch/icons';
import styled from 'styled-components';

import {
  NewButton,
  NewButtonSizes,
  NewButtonTypes,
} from '../../../components/Toolkit/NewButton/NewButton';

import { panelThemes, ThemeProps } from './theme';

const ThemeProvider = require('styled-components').ThemeProvider;

export type PanelProps = {
  image?: string;
  secondImage?: string;
  imgPosition?: 'start' | 'center';
  title: string | JSX.Element;
  subtitle: string | JSX.Element;
  background?: boolean;
  border?: boolean;
  close?: boolean;
  cta: 'BUTTON' | 'LINK';
  ctaText: string;
  ctaLink: string;
  onClick(): void;
  themeName?:
    | 'DEFAULT_VERTICAL_X_SMALL'
    | 'DEFAULT_VERTICAL_SMALL'
    | 'DEFAULT_VERTICAL_MEDIUM'
    | 'DEFAULT_VERTICAL_LARGE'
    | 'DEFAULT_HORIZONTAL_MEDIUM';
  customTheme?: ThemeProps;
  buttonSize: NewButtonSizes;
  buttonType: NewButtonTypes;
  id?: string;
  borderRadius?: boolean;
  opensInNewTab?: boolean;
};

const Container = styled.div<{
  background: boolean;
  border: boolean;
  borderRadius: boolean;
}>`
  position: relative;
  display: ${({ theme }) => (theme.panelTheme?.horizontal ? 'flex' : 'block')};
  padding: ${({ theme }) => theme.panelTheme?.panelPadding};
  width: 100%;

  border-color: ${({ border, theme }) =>
    border ? theme.panelTheme?.border?.borderColor : 'none'};
  border-style: ${({ border, theme }) =>
    border ? theme.panelTheme?.border?.borderStyle : 'none'};
  border-width: ${({ border, theme }) =>
    border ? theme.panelTheme?.border?.borderWidth : 'none'};
  border-radius: ${({ border, borderRadius, theme }) =>
    borderRadius
      ? theme.border.radius.STANDARD
      : border
        ? theme.panelTheme?.border?.borderWidth
        : 'none'};

  background-color: ${({ background, theme }) =>
    background ? `${theme.color.PRIMARY_LIGHTEST}` : `${theme.color.WHITE}`};
`;

const Title = styled.p<{ image: boolean }>`
  color: ${({ theme }) => theme.color.GREY_DARKER};
  inline-size: ${({ theme, image }) =>
    theme.panelTheme?.horizontal
      ? 'calc(100% - 10px)'
      : image
        ? null
        : `calc(100% - 60px)`};
  font-weight: ${({ theme }) => theme.panelTheme?.title.fontWeight};
  ${({ theme }) => theme.panelTheme?.title.fontSize};
  margin-bottom: ${({ theme }) => theme.panelTheme?.title.bottomMargin};
`;

const Subtitle = styled.div`
  color: ${({ theme }) => theme.color.GREY_DARKER};
  ${({ theme }) => theme.panelTheme?.subtitle.fontSize};
  margin-bottom: ${({ theme }) => theme.panelTheme?.subtitle.bottomMargin};
`;

const CloseContainer = styled.div`
  position: absolute;
  right: ${({ theme }) => theme.panelTheme?.closeIconMargin};
  top: ${({ theme }) => theme.panelTheme?.closeIconMargin};
  cursor: pointer;
  svg {
    width: ${({ theme }) => theme.spacing.M16};
    height: ${({ theme }) => theme.spacing.M16};
    color: ${({ theme }) => theme.color.GREY_DARK};
  }
`;

const ImageWrapper = styled.div<{ imgPosition: 'start' | 'center' }>`
  @media only screen and (min-width: 1004px) {
    display: flex;
    align-items: ${({ imgPosition }) => `${imgPosition}`};
  }
`;

const Image = styled.img`
  margin-right: ${({ theme }) =>
    theme.panelTheme?.horizontal ? theme.spacing.M24 : '0px'};
  max-width: 138px;
  max-height: 96px;
  margin-bottom: ${({ theme }) => theme.panelTheme?.imageBottomMargin};
`;

const SecondImage = styled.img`
  margin-left: ${({ theme }) => theme.spacing.M24};
  max-width: 160px;
  max-height: 20px;
`;

const LinkWrapper = styled.div`
  ${({ theme }) => theme.fontSize.S14};
  color: ${({ theme }) => theme.color.PRIMARY};
  font-weight: ${({ theme }) => theme.fontWeight.SEMIBOLD};

  a {
    color: ${({ theme }) => theme.color.PRIMARY};
  }
`;

export const Panel = ({
  image,
  secondImage,
  imgPosition = 'center',
  title,
  subtitle,
  background = false,
  border = false,
  close = false,
  cta,
  ctaText,
  ctaLink,
  themeName,
  customTheme,
  buttonSize,
  buttonType,
  onClick,
  id,
  borderRadius = false,
  opensInNewTab = false,
}: PanelProps) => {
  const panelTheme =
    customTheme ??
    panelThemes.get(themeName ?? 'DEFAULT_VERTICAL_STANDARD_X_SMALL');
  return (
    <ThemeProvider theme={{ panelTheme }}>
      <Container
        background={background}
        border={border}
        borderRadius={borderRadius}
        id={id ? `${id}-panel` : 'panel-div'}
        data-testid={id ? `${id}-panel` : 'panel-div'}
        data-tracking={id ? `${id}-panel` : 'panel-div'}
      >
        {close && (
          <CloseContainer data-testid="panel-close" onClick={onClick}>
            <CloseIcon />
          </CloseContainer>
        )}

        {image && (
          <ImageWrapper imgPosition={imgPosition}>
            <Image src={image} alt="panel-img" />
            {secondImage && <SecondImage src={secondImage} alt="panel-img-2" />}
          </ImageWrapper>
        )}

        <div>
          <Title image={!!image}>{title}</Title>
          <Subtitle>{subtitle}</Subtitle>
          {cta === 'BUTTON' ? (
            <NewButton
              data-testid={id ? `${id}-panel-cta` : 'panel-button-link'}
              href={ctaLink}
              buttonText={ctaText}
              buttonSize={buttonSize}
              buttonType={buttonType}
              target="_blank"
              data-tracking={id ? `${id}-panel-cta` : 'panel-button-link'}
              fullWidth
            />
          ) : (
            <LinkWrapper>
              <a
                href={ctaLink}
                data-tracking={id ? `${id}-panel-cta` : 'panel-button-link'}
                target={opensInNewTab ? '_blank' : '_self'}
                rel="noreferrer"
              >
                {ctaText}
              </a>
            </LinkWrapper>
          )}
        </div>
      </Container>
    </ThemeProvider>
  );
};
