import React from 'react';

import { AdSlot } from '@dsch/react-dfp';
import styled from 'styled-components';

import { DFPUnit } from '../../Toolkit/DFP/DFP';
import { DFPScreenSizes, DFPUnitSizes } from '../../Toolkit/DFP/DFPSizes';

const { MPU_300, BILLBOARD, LEADERBOARD } = DFPUnitSizes;

const {
  VIEW_DESKTOP,
  VIEW_DESKTOP_PLUS,
  VIEW_MOBILE,
  VIEW_MOBILE_PLUS,
  VIEW_TABLET_PLUS,
} = DFPScreenSizes;

const DFPStyledWrapper = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  z-index: 2;
  margin: 0 auto;

  @media only screen and (max-width: 425px) {
    top: 10px;
  }
`;

export const DFPWrapper = () => (
  <DFPStyledWrapper>
    <DFPUnit
      slotId="df_sp_homepage"
      adUnit="daft/df_sp_homepage"
      sizeMapping={[
        {
          viewport: VIEW_DESKTOP_PLUS,
          sizes: [BILLBOARD, 'fluid'],
        },
        {
          viewport: VIEW_DESKTOP,
          sizes: [LEADERBOARD, 'fluid'],
        },
        {
          viewport: VIEW_TABLET_PLUS,
          sizes: [LEADERBOARD, 'fluid'],
        },
        {
          viewport: VIEW_MOBILE_PLUS,
          sizes: [MPU_300, 'fluid'],
        },
        {
          viewport: VIEW_MOBILE,
          sizes: [MPU_300, 'fluid'],
        },
      ]}
      sizes={[MPU_300, BILLBOARD, LEADERBOARD]}
      AdSlot={AdSlot}
      disableBackground
    />
  </DFPStyledWrapper>
);
